import React from "react";
import axios from "axios";
import Config from "../../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withFormik } from 'formik';
import Select from 'react-select'
import Compress from "react-image-file-resizer";
import ImageUploader from '../../Components/ImageUploader';
import AsyncSelect from 'react-select/async';
import { Typeahead, AsyncTypeahead } from 'react-bootstrap-typeahead';
const NuevoCertificado = (props) => {
    const {
        values,
        touched, 
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
    } = props;

    const [courses,setCourses] = React.useState([]);
    const [estudiantes,setEstudiantes] = React.useState([]);
    const [tmp_photo,setTmpPhoto] = React.useState(null);
    React.useEffect(()=> {
        getCourses();
        getEstudiantes();
    },[]) 


    async function getCourses() {

        await axios.get(Config.url+'cursos/', { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                let options = [];
                console.log(response);
                
                for (var i = 0; i < response.data.data.length; i++) {
                    let data = response.data.data[i];
                    options.push({
                        value: data.id,
                        label: data.titulo
                    })
                 }
                 setCourses(...[options]);
                
                
            })
            .catch(error => {
                console.log(error);
                toast.error('Error');
            })

    }


    async function getEstudiantes(search) {

        await axios.get(Config.url+'estudiantes?searchValue='+search, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                console.log(response);
                console.log(response);
                let optionss = response.data.data.map( data => ({ value: data.id, label: data.firstName +" "+data.lastName+" "+data.email }));
                setEstudiantes(...[optionss])
            })
            .catch(error => {
                console.log(error);
                toast.error('Error');
            })

    }

    const handleChangeStudent = (e) => {
        console.log(e);
        if(e.length > 0){
            setFieldValue('estudianteId', e[0].value, false)
        }
        
    }

    const handleChangeCourse = (e) => {
        console.log(e);
        setFieldValue('cursoId', e.value, false)
    }

    

    return (
        <>
            <ToastContainer />
            <form onSubmit={handleSubmit}>
                {/* INICIO DEL FORMULARIO */}
                
                <div className="row layout-top-spacing">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                        <div className="statbox widget box box-shadow">
                            <div className="widget-header">
                                <div className="row">
                                    <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                                        <h4>NEW CERTIFICATE</h4> {errors.student && touched.student && errors.student}
                                    </div>
                                </div>
                            </div>
                            <div className="widget-content widget-content-area">
                                <div className="row">
                                    
                                {tmp_photo  && 
                                    <div className="col-lg-12 text-center">
                                        <img src={tmp_photo} style={{width:300}} className="rounded-circle" />
                                    </div>
                                    }
                                
                                    <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label>Photo</label>
                                            <ImageUploader setTmpPhoto={setTmpPhoto} setFieldValue={setFieldValue}/>
                                        
                                        </div>
                                        <div className="form-group mb-3">
                                            
                                            <input type="hidden" className="form-control"
                                            onChange={(e)=>{handleChange(e);}}
                                            onBlur={handleBlur}
                                            name="foto"
                                            value={values.foto || ''}
                                            />
                                            {errors.foto && touched.foto && errors.foto}
                                        </div>
                                    </div>
                                

                                <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label>Select Student</label>
                                            <AsyncTypeahead
                                                id="async-example"
                                                isLoading={false}
                                                labelKey={option => `${option.label}`}
                                                onSearch={(query) => {
                                                    getEstudiantes(query)
                                                }}
                                                onChange={handleChangeStudent}
                                                options={estudiantes}
                                                />
                                        {/* <Select options={estudiantes} onChange={(e)=>{values.estudiante=e.value}} />
                                        {errors.estudiante && touched.estudiante && errors.estudiante} */}
                                        {errors.estudianteId && touched.estudianteId && errors.estudianteId}
                                        </div>
                                </div>
                                <div className="col-12">
                                        <div className="form-group mb-3">
                                        <label>Select Course</label>
                                        <Select options={courses} onChange={(e)=>{handleChangeCourse(e)}}/>
                                        {errors.cursoId && touched.cursoId && errors.cursoId}
                                        </div>
                                </div>

                                <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label>Date Issue</label>
                                            <input type="date" className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="fechaEmision"
                                            value={values.fechaEmision || ''}
                                            />
                                            {errors.fechaEmision && touched.fechaEmision && errors.fechaEmision}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label>Date Expired</label>
                                            <input type="date" className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="fechaExpiracion"
                                            value={values.fechaExpiracion || ''}
                                            />
                                            {errors.fechaExpiracion && touched.fechaExpiracion && errors.fechaExpiracion}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label>Verified By</label>
                                            <input type="text" className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="verificadoPor"
                                            value={values.verificadoPor || ''}
                                            />
                                            {errors.verificadoPor && touched.verificadoPor && errors.verificadoPor}
                                        </div>
                                    </div>
                                    {/* <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label>Course Code</label>
                                            <input type="text" className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="coursecode"
                                            value={values.coursecode || ''}
                                            />
                                            {errors.coursecode && touched.coursecode && errors.coursecode}
                                        </div>
                                    </div> */}
                                    <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label>Training Hours</label>
                                            <input type="string" className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="horasEntrenamiento"
                                            value={values.horasEntrenamiento || ''}
                                            />
                                            {errors.horasEntrenamiento && touched.horasEntrenamiento && errors.horasEntrenamiento}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label>Trainer Name</label>
                                            <input type="text" className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="entrenador"
                                            value={values.entrenador || ''}
                                            />
                                            {errors.entrenador && touched.entrenador && errors.entrenador}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label>Trainer ID</label>
                                            <input type="text" className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="entrenador_ID"
                                            value={values.entrenador_ID || ''}
                                            />
                                            {errors.entrenador_ID && touched.entrenador_ID && errors.entrenador_ID}
                                        </div>
                                    </div>

                                </div>
                                <button type="submit" className="btn btn-success" disabled={isSubmitting}>
                                        Submit
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default withFormik(
    {
        enableReinitialize: true,
        // default values
        mapPropsToValues: () => ({}),
        validate: values => {
            const errors = {};
            if(!values.estudianteId){
                errors.estudianteId='Requerido';
                //toast.error('Estudiante Requerido');
            }
            if(!values.cursoId){
                errors.cursoId='Requerido';
                //toast.error('Curso Requerido');
            }
            if(!values.fechaExpiracion){
                errors.fechaExpiracion='Requerido';
                //toast.error('Fecha Expiración Requerido');
            }
            if(!values.fechaEmision){
                errors.fechaEmision='Requerido';
                //toast.error('Fecha Emisión Requerido');
            }
            if(!values.verificadoPor){
                errors.verificadoPor='Requerido';
                //toast.error('Verificado Por Requerido');
            }
            if(!values.entrenador){
                errors.entrenador='Requerido';
                //toast.error('Entrenador Requerido');
            }
            if(!values.entrenador_ID){
                errors.entrenador_ID='Requerido';
                //toast.error('Entrenador Id Requerido');
            }
            console.log(errors);
            return errors;
            // validation
        },
        handleSubmit: async (values, {resetForm, setSubmitting }) => {

            console.log(values);
            //return;
            await axios.post(Config.url+'certificados/', values, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                console.log(response);
                toast.success('Certificate Successfully');
                setTimeout(()=>{
                    window.location.reload();
                },3000)

            })
            .catch(error => {
                console.log(error);
                toast.error('Error.');

            })
            


            // setTimeout(() => {

            //     alert(JSON.stringify(values, null, 2));

            //     setSubmitting(false);

            // }, 1000);

        },
    }
)(NuevoCertificado);
