import React from 'react'
import { Route, Redirect } from 'react-router-dom'

function UnauthenticatedAppRoute({ component: Component, layout : Layout ,  ...rest }){
  const token=localStorage.getItem("token");
  return(
    <Route
      {...rest}
      render={props => (
               
        <Layout>
          <Component {...props}/>
        </Layout>
      ) 
      }
    />
  )
}
export default UnauthenticatedAppRoute;