import React from "react";
import { Nav, Navbar, Container, Row, Col} from "react-bootstrap";
import {LinkContainer} from 'react-router-bootstrap'
import './Dashboard.css';
function DashboardLayout({children}){
return(

    <>
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
  <Navbar.Brand href="#">My Comply</Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <LinkContainer to="/0f29ddd4be2fe3e504e308f0e9827a39/admin/students">
        <Nav.Link>Students</Nav.Link>
      </LinkContainer>
      <LinkContainer to="/0f29ddd4be2fe3e504e308f0e9827a39/admin/courses">
        <Nav.Link>Courses</Nav.Link>
      </LinkContainer>
      <LinkContainer to="/0f29ddd4be2fe3e504e308f0e9827a39/admin/certificates">
        <Nav.Link>Certificates</Nav.Link>
      </LinkContainer>
      
    </Nav>
    <Nav>
    <LinkContainer to="/0f29ddd4be2fe3e504e308f0e9827a39/admin/logout">
        <Nav.Link>Logout</Nav.Link>
    </LinkContainer>
    </Nav>
  </Navbar.Collapse>
</Navbar>
<Container fluid>
                
                <Row>
                <Col>
                        {children}
                    </Col> 
                </Row>

            </Container>
    </>
    
);
}

export default DashboardLayout;