import React from 'react'
import { Route, Redirect } from 'react-router-dom'

function AppRoute({ component: Component, layout : Layout ,  ...rest }){
  const token=localStorage.getItem("token");
  return(
    <Route
      {...rest}
      render={props =>
        token != null
        ? 
        <Layout>
          <Component {...props}/>
        </Layout>
        : <Redirect to="/login" />
      }
    />
  )
}
export default AppRoute;