import React from "react";
import Pagination from "react-bootstrap/Pagination";
const PaginationComponent = ({ paginationData, paginate, searchValue }) => {
  console.log(paginationData);
  let active = paginationData.pageNumber;
  let items = [];
  items.push(
    <Pagination.First onClick={() => paginate(1,searchValue)} disabled={active === 1}/>
  
  )
  items.push(
  
  <Pagination.Prev onClick={() => paginate((active - 1),searchValue)} disabled={active === 1}/>
  )
  for (let number = 1; number <= 5 ;number++) {
    if(paginationData.totalPages > 5){
      items.push(
      <Pagination.Item key={number} href="#" onClick={() => paginate(number,searchValue)} activeLabel={''} active={number === active}>
        {number}
      </Pagination.Item>
    );
    }
  }
  
  items.push(
    <Pagination.Ellipsis />
  
  )
  items.push(
  <Pagination.Item onClick={() => paginate(paginationData.totalPages,searchValue)} activeLabel={''} active={paginationData.totalPages === active}>{paginationData.totalPages}</Pagination.Item>
  )
  items.push(
    <Pagination.Next onClick={() => paginate((active +1),searchValue)} disabled={active === paginationData.totalPages}/>
  )
  items.push(
    <Pagination.Last onClick={() => paginate(paginationData.totalPages,searchValue)} disabled={active === paginationData.totalPages}/>
  )

  const paginationBasic = (
    <div className="row">
      <div className="col-lg-12">
      <Pagination maxButtons={7}>{items}</Pagination>
      </div>
      
    </div>
  );

  return paginationBasic;
};

export default PaginationComponent;
